/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const Survicate = ({ sliderSettings }) => {
  const { img1, img2, img3, img4 } = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "marketplace/survicate/1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2: file(relativePath: { eq: "marketplace/survicate/2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img3: file(relativePath: { eq: "marketplace/survicate/4.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img4: file(relativePath: { eq: "marketplace/survicate/8.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp.fluid,
      alt: 'Survicate Integration',
      title: 'Integrate Survicate with LiveSession',
    },
    {
      img: img2.childImageSharp.fluid,
      alt: 'Survicate Integration',
      title: 'Integrate Survicate with LiveSession',
    },
    {
      img: img3.childImageSharp.fluid,
      alt: 'Survicate Integration',
      title: 'Integrate Survicate with LiveSession',
    },
    {
      img: img4.childImageSharp.fluid,
      alt: 'Survicate Integration',
      title: 'Integrate Survicate with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)}>
        {photos.map(({ img, alt, title }) => (
          <div key={img}>
            <Img fluid={img} alt={alt} title={title} />
          </div>
        ))}
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Survicate is a powerful survey, NPS and feedback management tool that helps businesses
          capture more customer feedback. The integration with LiveSession lets you watch session
          recordings associated with the corresponding survey responses. We&apos;ll send a link to
          the recording to the respondent&apos;s profile in Survicate so you can access it from
          there.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Access session recordings straight from Survicate </li>
          <li>Identify and fix product and website issues </li>
          <li>See for yourself what the user experienced</li>
          <li>Discover more context behind customer feedback and better understand it</li>
          <li>Use Survicate data as custom properties in LiveSession</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Just copy and paste a piece of code. See a detailed tutorial in our{' '}
        <a href="/help/survicate-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Survicate);

Survicate.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
